<template>
  <div class="columns mt-3 is-multiline">
    <div class="column is-12">
      <div class="form-container ">
        <ValidSelect
          rules="required"
          label="¿Qué producto financiero es?"
          fieldClass="label-big"
          placeholder="Selecciona un producto financiero..."
          v-model="producto_monto.producto_financiero"
          :data="getProductosFinancieros"
          show="alias"
          :disabled="(linea.id !== null && (linea.pasiva == 0 || !pasivo)) || financiando || esArrendamiento"
          realValue="id"
          :expanded="true"
        />
      </div>
    </div>
    <div class="column is-12">
      <div class="form-container">
        <label class="label-big font-bold has-text-grey-darker"
          >{{esArrendamiento ? '¿Cuál es el valor del bien?' : '¿Qué monto quieres?'}}</label
        >
        <div class="columns is-multiline mt-1">
          <div class="column is-2">
            <ValidSelect
              rules="required"
              label="Moneda"
              placeholder="Selecciona una moneda..."
              v-model="producto_monto.moneda"
              :data="getMonedas"
              :disabled="disabled"
              :expanded="true"
              show="nombre"
              realValue="id"
            />
          </div>
          <div class="column is-10">
            <ValidCurrencyInput
              ref="monto"
              rules="required|currency|notcero"
              :label="esArrendamiento ? 'Valor del bien' : 'Cantidad'"
              placeholder="0.00"
              v-model="producto_monto.monto"
              :disabled="disabled"
              :expanded="true"
            />
          </div>
          <div class="column is-6">
            <ValidInput
              rules="min_value:0.01"
              label="Valor residual"
              placeholder="0"
              v-model="producto_monto.valor_residual"
              type="number"
              :expanded="true"
              addon
              addonIcon="percentage"
              v-if="esArrendamiento"
            />
          </div>
          <div class="column is-6">
            <ValidCurrencyInput
              expanded
              rules="required|currency|notcero"
              label="Enganche"
              placeholder="0.00"
              v-model="producto_monto.enganche"
              type="number"
              v-if="esArrendamiento"
            />
          </div>
        </div>
      </div>
      <garantia-card v-if="esArrendamiento && bienArrendable" class="py-6 mx-6 mb-3" :garantia="bienArrendable" :arrendamiento="esArrendamiento" :arrendando="true" />
      <div v-if="esArrendamiento && !bienArrendable" class="mx-6">
        <valid-auto-complete
          rules="required"
          label="Seleccionar activo"
          placeholder="Buscar..."
          v-model="activoSearch"
          :data="activosExistentes"
          show="descripcion"
          class="text-left"
          @typing="buscarActivo"
          @select="setActivoExistente"
          :expanded="true"
        />
      </div>
    </div>
    <div v-if="pasivo" class="column is-4">
      <div class="form-container ">
        <valid-input
        label="ID fondeador"
        placeholder="Introduzca una descripción..."
        v-model="identificador_fondeo"
        type="text"
        :expanded="true"
      />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidInput from "@/components/form/ValidInput";
import ValidSelect from "@/components/form/ValidSelect";
import GarantiaCard from "@/components/cards/GarantiaCard";
import GarantiasApi from "@/api/garantias.api";
import { debounce } from "@/vendors/helpers";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";

export default {
  name: "producto_monto",
  data() {
    return {
        activoSearch: "",
        activoSelected: null,
        activosExistentes: [],
        identificador_fondeo: null,
    };
  },
  props: {
    producto_monto: Object,
    pasivo: Boolean,
    linea: Object,
    bienArrendable: Object,
    creditoGrupal: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ValidCurrencyInput,
    ValidInput,
    ValidSelect,
    GarantiaCard,
    ValidAutoComplete
  },
  watch: {
    producto_monto: {
      handler(val) {
        if (val.producto_financiero !== null) {
          this.producto_monto.producto_objeto = this.getProductosFinancieros.find(
            producto => producto.id == val.producto_financiero
          );
        }
      },
      deep: true
    },
    'producto_monto.producto_objeto': {
      handler(val) {
        if(val && this.esArrendamiento && this.bienArrendable) {
          this.producto_monto.monto = this.bienArrendable.valor;
          this.producto_monto.moneda = this.bienArrendable.moneda.id;
        }
      },
      deep: true
    },
    bienArrendable(val) {
      if(val && this.esArrendamiento && this.producto_monto) {
          this.producto_monto.monto = this.bienArrendable.valor;
          this.producto_monto.moneda = this.bienArrendable.moneda.id;
        }
    },
    identificador_fondeo(val) {
      this.$emit('asignarIdentificadorFondeador', val);
    }
  },
  methods: {
    buscarActivo: debounce(function(search) {
      if (!search.length) {
        return;
      }
      GarantiasApi.buscarActivoParaArrendamiento(search).then(res => {
        this.activosExistentes = res.data ? res.data.garantias : []
      });
    }, 400),
    setActivoExistente(activo) {
      this.$emit('asignarBienArrendable', activo);
    },
  },
  computed: {
    ...mapGetters("catalogos", ["getMonedas", "getProductosFinancieros"]),
    
    financiando() {
      return this.$route.name == "financiar_cuenta" ? true : false;
    },
    esArrendamiento(){
      return this.producto_monto && this.producto_monto.producto_objeto 
        ? this.producto_monto.producto_objeto.tipo_credito.tipo === "Arrendamiento Puro" 
        : this.$route.name == 'solicitar_arrendamiento' || false;
    },
    disabled() {
      if (this.financiando || this.creditoGrupal || (this.esArrendamiento && this.bienArrendable)) return true;
      return false;
    }
  },
  created() {
    if (this.producto_monto.producto_financiero) {
      this.producto_monto.producto_objeto = this.getProductosFinancieros.find(
        producto => producto.id == this.producto_monto.producto_financiero
      );
    }
    
  }
};
</script>
