<template>
  <Layout>
    <Bienvenido
      v-show="welcome && !financiando && !esArrendamiento && !esCreditoGrupal"
      :pasivo="pasivo"
      @closeWelcome="closeWelcome"
    />
    <Finalizado v-show="finish" />
    <b-message
      aria-close-label="Cerrar mensaje"
      :active.sync="continuacion"
      class="shadow-capvital main-cols has-background-white text-left"
    >
      <div class="columns is-multiline is-mobile relative">
        <div class="column">
          <p class="title has-text-secondary">Disposición en proceso</p>
        </div>
        <div class="column text-right absolute right-0 md:relative">
          <b-icon
            icon="times-circle"
            class="cursor-pointer hover:text-red-400 has-text-grey"
            @click.prevent.native="() => (continuacion = false)"
          />
        </div>
        <div class="column is-12">
          <p>
            Se ha encontrado una disposición en proceso, los datos de la misma
            han sido cargados y es posible continuar con su elaboración, este
            mensaje se cerrará al continuar con la solicitud o al darle click en
            la esquina superior derecha al botón de ocultar
          </p>
          <p>
            Si deseas descartar esta solicitud de disposición da click en el
            botón de descartar
          </p>
          <b-button
            type="is-text"
            class="has-text-danger"
            @click="eliminarContinuacion"
            >Descartar</b-button
          >
        </div>
      </div>
    </b-message>
    <div class="pl-5 mb-8 text-left" v-if="financiando && !welcome && !finish">
      <p class="text-3xl font-bold">Configurar cuenta financiada</p>
      <h2 class="text-lg">
        {{ tipo_cuenta.nombre }}
      </h2>
    </div>
    <div
      class="columns main-cols pb-4 is-vcentered"
      v-show="!welcome && !finish"
    >
      <ValidationObserver ref="observer" v-slot="{ validate }" class="w-full">
        <div class="column">
          <b-steps
            :type="!pasivo ? esArrendamiento ? 'is-arrendamiento' : 'is-primary' : 'is-pasivo'"
            :animated="true"
            v-model="activeStep"
            :has-navigation="true"
            class="tr animated fadeIn"
            :class="{ editar: editar == true }"
          >
            <b-step-item label="Producto y monto" :clickable="editar">
              <ProductoMontoStep
                v-if="activeStep == 0"
                ref="producto_monto"
                :pasivo="pasivo"
                :producto_monto="producto_monto"
                :linea="linea"
                :bienArrendable="getGarantiaParaArrendar"
                :creditoGrupal="esCreditoGrupal"
                @asignarBienArrendable="asignarBienArrendable"
                @asignarIdentificadorFondeador="asignarIdentificadorFondeador"
              />
            </b-step-item>
            <b-step-item v-if="esCreditoGrupal" label="Cantidades grupo" :clickable="editar">
              <CantidadesGrupoStep
                v-if="activeStep == 1"
                ref="cantidades_grupo"
                :personas="cantidades_grupo"
                :capital="producto_monto.monto"
              />
            </b-step-item>
            <b-step-item label="Interés ordinario" :clickable="editar">
              <InteresOrdinarioStep
                v-if="((!esCreditoGrupal && activeStep == 1) || (esCreditoGrupal && activeStep == 2)) && getTratamientoIntereses.length > 0"
                :pasivo="pasivo"
                :credito_activo="credito_activo"
                :interes_ordinario="interes_ordinario"
                :tratamiento_capital="tratamiento_capital"
                :producto_objeto="producto_monto.producto_objeto"
                :dias_antes_provision="dias_antes_provision"
                :arrendamiento="esArrendamiento"
              />
            </b-step-item>
            <b-step-item label="Interés moratorio" :clickable="editar">
              <InteresMoratorioStep
                v-if="((!esCreditoGrupal && activeStep == 2) || (esCreditoGrupal && activeStep == 3))"
                :pasivo="pasivo"
                :interes_moratorio="interes_moratorio"
                :interes_ordinario="interes_ordinario"
                :producto_objeto="producto_monto.producto_objeto"
              />
            </b-step-item>
            <b-step-item label="Tratamiento de capital" :clickable="editar">
              <TratamientoCapitalStep
                v-if="((!esCreditoGrupal && activeStep == 3) || (esCreditoGrupal && activeStep == 4))"
                :pasivo="pasivo"
                :interes_ordinario="interes_ordinario"
                :producto_monto="producto_monto"
                :tratamiento_capital="tratamiento_capital"
              />
            </b-step-item>
            <b-step-item label="Accesorios" :clickable="editar">
              <AccesoriosStep
                v-if="((!esCreditoGrupal && activeStep == 4) || (esCreditoGrupal && activeStep == 5))"
                :pasivo="pasivo"
                :accesorios="accesorios"
                :producto_monto="producto_monto"
              />
            </b-step-item>
            <b-step-item label="Resumen" :clickable="editar">
              <ResumenStep
                v-if="
                  ((!esCreditoGrupal && activeStep == 5) || (esCreditoGrupal && activeStep == 6)) &&
                    getProductosFinancieros.length > 0 &&
                    getTiposPago.length > 0
                "
                :pasivo="pasivo"
                :producto_monto="producto_monto"
                :tratamiento_capital="tratamiento_capital"
                :interes_ordinario="interes_ordinario"
                :interes_moratorio="interes_moratorio"
                :accesorios="accesorios"
                :linea="linea"
                :esCreditoGrupal="esCreditoGrupal"
                @editResumen="editResumen"
              />
            </b-step-item>
            <template slot="navigation" slot-scope="{ previous, next }">
              <div class="columns">
                <div
                  class="column"
                  :class="{
                    'is-offset-0 is-3': (esCreditoGrupal && activeStep == 6) || (!esCreditoGrupal && activeStep == 5),
                    'is-offset-9': (esCreditoGrupal && activeStep != 6) || (!esCreditoGrupal && activeStep != 5)
                  }"
                >
                  <b-button
                    type="is-light"
                    outlined
                    expanded
                    class="has-text-grey-darker"
                    v-if="!previous.disabled && ((esCreditoGrupal && activeStep != 6) || (!esCreditoGrupal && activeStep != 5))"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action"
                    >Anterior</b-button
                  >
                  <b-button
                    type="is-light"
                    expanded
                    v-if="!previous.disabled && ((esCreditoGrupal && activeStep == 6) || (!esCreditoGrupal && activeStep == 5))"
                    :disabled="previous.disabled"
                    @click="confirmCancelDisposicion"
                    >Cancelar operación</b-button
                  >
                </div>
                <div class="column">
                  <b-button
                    expanded
                    :type="!pasivo ? esArrendamiento ? 'is-arrendamiento' : 'is-primary' : 'is-pasivo'"
                    :disabled="next.disabled && ((esCreditoGrupal && activeStep != 6) || (!esCreditoGrupal && activeStep != 5))"
                    @click="validate().then(result => goToNext(result, next))"
                    >{{ stepText }}</b-button
                  >
                </div>
              </div>
            </template>
          </b-steps>
        </div>
      </ValidationObserver>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="toggleModal"
        :active.sync="toggleModal"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-destino-localidad
          class="modal-has-overflow"
          :linea="linea"
          :disposicion="getDisposicionDetalle"
          @continuar="simular"
          :pasivo="pasivo"
          :arrendamiento="esArrendamiento"
        />
      </b-modal>
    </portal>
  </Layout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import Bienvenido from "@/components/SolicitarDisposicionSteps/Bienvenido";
import Finalizado from "@/components/SolicitarDisposicionSteps/Finalizado";
import ProductoMontoStep from "@/components/SolicitarDisposicionSteps/ProductoMonto";
import CantidadesGrupoStep from "@/components/SolicitarDisposicionSteps/CantidadesGrupo";
import TratamientoCapitalStep from "@/components/SolicitarDisposicionSteps/TratamientoCapital";
import InteresOrdinarioStep from "@/components/SolicitarDisposicionSteps/InteresOrdinario";
import InteresMoratorioStep from "@/components/SolicitarDisposicionSteps/InteresMoratorio";
import AccesoriosStep from "@/components/SolicitarDisposicionSteps/Accesorios";
import ResumenStep from "@/components/SolicitarDisposicionSteps/Resumen";
import ModalDestinoLocalidad from "@/components/modals/ModalDestinoLocalidad";
import LineasCreditoApi from "@/api/lineascredito.api";

export default {
  name: "solicitarDisposicion",
  data() {
    return {
      description: "Cargando",
      dias_antes_provision: null,
      continuacion: false,
      credito_activo: false,
      activeStep: 0,
      editar: false,
      welcome: true,
      finish: false,
      pasivo: false,
      toggleModal: false,
      identificador_fondeador: null,
      activoParaArrendamiento: null,
      producto_monto: {
        producto_financiero: null,
        producto_objeto: null,
        moneda: 1,
        monto: null
      },
      cantidades_grupo: [],
      tratamiento_capital: {
        tipo_pago: null,
        isRegular: false,
        numero_tiempo: null,
        periodo: "days",
        fecha_vencimiento: null,
        plazo_dias: null,
        tabla_irregular: {
          data: [{ fecha_pago: null, abono_capital: 0 }],
          columns: [
            {
              field: "fecha_pago",
              label: "Fecha de pago"
            },
            {
              field: "abono_capital",
              label: "Abono a capital"
            }
          ]
        }
      },
      interes_ordinario: {
        tasa_base: null,
        tasa: null,
        dia_inhabil: null,
        tipo_cobro: null,
        fecha_corte: null,
        forma_calculo: 0,
        iva: false,
        refinanciamiento: 0,
        provision_anticipada: null
      },
      interes_moratorio: {
        tasa_base: null,
        tasa: null,
        iva: 0,
        veces: 1,
        tipo: null
      },
      accesorios: {
        editando: false,
        detalles: []
      },
      resumen: {
        edit_producto_monto: false,
        edit_tratamiento_capital: false,
        edit_interes_ordinario: false,
        edit_interes_moratorio: false,
        edit_accesorios: false
      },
      linea: {
        persona_id: null,
        id: null,
        destino_recursos: null,
        localidad_id: null
      }
    };
  },
  components: {
    AccesoriosStep,
    Bienvenido,
    Finalizado,
    InteresMoratorioStep,
    InteresOrdinarioStep,
    ProductoMontoStep,
    ResumenStep,
    TratamientoCapitalStep,
    ValidationObserver,
    ModalDestinoLocalidad,
    CantidadesGrupoStep,
  },
  methods: {
    //Cierra la pantalla de bienvenida
    closeWelcome() {
      this.welcome = false;
    },
    //Cancela la disposición en el resumén de la misma
    confirmCancelDisposicion() {
      this.$buefy.dialog.confirm({
        title: `Cancelar solicitud de ${ this.esArrendamiento ? 'arrendamiento' : 'disposición' }`,
        message:
          `¿Estas seguro que deseas cancelar la creación o edición de la solicitud de ${ this.esArrendamiento ? 'arrendamiento' : 'disposición' }?`,
        confirmText: "Si, cancelar Solicitud",
        cancelText: "No, conservar",
        type: "is-danger",
        onConfirm: () => this.$router.replace("/disposiciones")
      });
    },
    /*Pone activo el paso al que se quiere editar
      @tipo(Int) = index del paso
    */
    editResumen(tipo) {
      this.activeStep = tipo;
      switch (tipo) {
        case 0:
          this.resumen.edit_producto_monto = !this.resumen.edit_producto_monto;
          break;
        case 1:
          this.resumen.edit_tratamiento_capital = !this.resumen
            .edit_tratamiento_capital;
          break;
        case 2:
          this.resumen.edit_interes_ordinario = !this.resumen
            .edit_interes_ordinario;
          break;
        case 3:
          this.resumen.edit_interes_moratorio = !this.resumen
            .edit_interes_moratorio;

          if (this.tratamiento_capital.fecha_inicio) {
            this.tratamiento_capital.fecha_inicio = this.$moment(
              this.tratamiento_capital.fecha_inicio
            ).toDate();
          } else {
            this.tratamiento_capital.fecha_inicio = this.$moment(
              this.getSofom.fecha_cierre
            )
              .add(1, "days")
              .toDate();
          }
          break;
        case 4:
          this.resumen.edit_accesorios = !this.resumen.edit_accesorios;
          break;
      }
    },
    /*Función encargada de ir al siguiente paso
      @result(Bool) resultado de las validaciones de los inputs
      @next siguiente paso (propiedad de b-steps)
    */
    goToNext(result, next) {
      if (result) {
        if (Object.values(this.resumen).includes(true)) {
          if (((this.esCreditoGrupal && this.activeStep == 5) || (!this.esCreditoGrupal && this.activeStep == 4)) && this.accesorios.editando == true) {
            this.notification(
              "warning",
              "Accesorio no agregado",
              `Necesitas agregar el accesorio antes de continuar`
            );
            return;
          }
          if (
            this.tratamiento_capital.fecha_inicio >
              this.tratamiento_capital.tabla_irregular.data[0].fecha_pago &&
            this.tratamiento_capital.tipo_pago != "vencimiento"
          ) {
            this.notification(
              "warning",
              "Fecha de inicio",
              `La fecha de inicio debe ser mayor o igual a la fecha de entrega`
            );
            return;
          }
          this.activeStep = this.esCreditoGrupoal ? 6 : 5;
          const keys = Object.keys(this.resumen);
          for (const key of keys) {
            this.resumen[key] = 0;
          }
          return;
        }
        switch (this.activeStep) {
          case 0:
            if (this.tratamiento_capital.fecha_vencimiento == null) {
              this.tratamiento_capital.fecha_vencimiento = this.getFechaCierre;
            }
            this.tratamiento_capital.tabla_irregular.data[0].fecha_pago = this.getFechaCierre;

            if (this.esArrendamiento && !this.getGarantiaParaArrendar) {
              this.notification(
                "warning",
                "Sin bien arrendable",
                `No has seleccionado un bien arrendable`
              );
              return;
            }
            break;
          case (this.esCreditoGrupal ? 4 : 3):
            if (
              this.tratamiento_capital.tipo_pago == "irregulares" &&
              this.insolutoRestante >= 0.01
            ) {
              this.notification(
                "warning",
                "Monto insoluto",
                `El Monto insoluto debe estar en 0 para poder continuar`
              );
              return;
            }
            if (
              this.tratamiento_capital.fecha_inicio >
                this.tratamiento_capital.tabla_irregular.data[0].fecha_pago &&
              this.tratamiento_capital.tipo_pago != "vencimiento"
            ) {
              this.notification(
                "warning",
                "Fecha de inicio",
                `La fecha de inicio debe ser mayor o igual a la fecha de entrega`
              );
              return;
            }
            if (this.interes_ordinario.tasa_base == null) {
              this.interes_ordinario.tasa_base = this.getTasasBase.find(
                x => x.nombre == "Tasa Fija"
              ).id;
            }
            break;
          case (this.esCreditoGrupal ? 2 : 1):
            if (this.interes_moratorio.tasa_base == null) {
              this.interes_moratorio.tasa_base = this.getTasasBase.find(
                x => x.nombre == "Tasa Fija"
              ).id;
            }
            break;
          case (this.esCreditoGrupal ? 5 : 4):
            if (this.accesorios.editando == true) {
              this.notification(
                "warning",
                "Accesorio no agregado",
                `Necesitas agregar el accesorio antes de continuar`
              );
              return;
            }
            break;
          case (this.esCreditoGrupal ? 6 : 5):
            if (
              this.$route.params.linea_credito_id ||
              this.$route.name == "fondear_disposicion" ||
              this.$route.name == "financiar_cuenta"
            ) {
              this.toggleModal = true;
            } else {
              this.simular();
            }
            break;
        }
        this.guardarTemporalmente();
        next.action();
      }
    },
    //Guarda el proceso de la solicitud en localstorage
    guardarTemporalmente() {
      this.continuacion == true ? (this.continuacion = false) : null;
      if (this.esArrendamiento && this.getGarantiaParaArrendar) this.activoParaArrendamiento = this.getGarantiaParaArrendar;
      localStorage.setItem("pasos", JSON.stringify(this.$data));
    },
    //Petición a API al ir en el ultimo paso
    async simular(datos_linea = null) {
      if (datos_linea != null) {
        this.linea = datos_linea;
      }
      const monto_temp = this.producto_monto.monto;
      const fecha_vencimiento_temp = this.tratamiento_capital.fecha_vencimiento;
      const detalles_irregular = JSON.parse(
        JSON.stringify(this.tratamiento_capital.tabla_irregular.data)
      );
      const accesorios = JSON.parse(JSON.stringify(this.accesorios.detalles));
      if (this.tratamiento_capital.tipo_pago == "irregulares") {
        let inicio = this.$moment(
          this.tratamiento_capital.fecha_inicio
        );
        let fin = this.$moment(
          this.tratamiento_capital.tabla_irregular.data[
            this.tratamiento_capital.tabla_irregular.data.length - 1
          ].fecha_pago
        );
        this.tratamiento_capital.plazo_dias = fin.diff(inicio, "days");
      }
      this.tratamiento_capital.fecha_vencimiento = this.$moment(
        this.tratamiento_capital.fecha_vencimiento
      ).format("YYYY-MM-DD");
      this.tratamiento_capital.tabla_irregular.data.forEach(x => {
        x.fecha_pago = this.$moment(x.fecha_pago).format("YYYY-MM-DD");
      });
      this.accesorios.detalles.forEach(accesorio => {
        accesorio.fecha_vencimiento = this.$moment(
          accesorio.fecha_vencimiento
        ).format("YYYY-MM-DD");
      });
      
      if (this.esArrendamiento) {
        this.tratamiento_capital.tipo_pago = 'regulares';
      }

      if (this.$route.name == "editar_disposicion") {
        this.$store
          .dispatch("disposicion/editarDisposicion", {
            id: this.getDisposicionDetalle.id,
            pasivo: this.pasivo,
            dias_antes_provision: this.interes_ordinario.provision_anticipada,
            disposicion_status: this.getDisposicionDetalle.credito_status
              .nombre,
            producto_monto: this.producto_monto,
            tratamiento_capital: this.tratamiento_capital,
            interes_ordinario: this.interes_ordinario,
            interes_moratorio: this.interes_moratorio,
            accesorios: this.accesorios.detalles,
            arrendamiento: this.esArrendamiento ? { valor_residual: this.producto_monto.monto * (this.producto_monto.valor_residual / 100) } : null,
            activo_id: this.esArrendamiento ? this.getGarantiaParaArrendar.id : null
          })
          .then(res => {
            this.producto_monto.monto = monto_temp;
            this.tratamiento_capital.fecha_vencimiento = fecha_vencimiento_temp;
            this.tratamiento_capital.tabla_irregular.data = detalles_irregular;
            this.accesorios.detalles = accesorios;
            if (!res.response) {
              this.finish = true;
              localStorage.removeItem("pasos");
            }
          });
      } else if (this.$route.name == "financiar_cuenta") {
        const cuenta = await this.$store.dispatch(
          "lineascredito/crearCuenta",
          this.getCuenta
        );
        this.$store
          .dispatch("disposicion/financiarCuenta", {
            cuenta_id: cuenta.id,
            credito_core_id: this.getDisposicionDetalle
              ? this.getDisposicionDetalle.id
              : null,
            linea_credito_id: this.getCuenta.lineadecredito_id
              ? this.getCuenta.lineadecredito_id
              : null,
            pasivo: this.pasivo,
            disposicion_status: "Prospecto",
            dias_antes_provision: this.interes_ordinario.provision_anticipada,
            producto_monto: this.producto_monto,
            tratamiento_capital: this.tratamiento_capital,
            interes_ordinario: this.interes_ordinario,
            interes_moratorio: this.interes_moratorio,
            accesorios: this.accesorios.detalles,
            linea: this.linea,
            identificador_fondeador: this.identificador_fondeador
          })
          .then(res => {
            this.producto_monto.monto = monto_temp;
            this.tratamiento_capital.fecha_vencimiento = fecha_vencimiento_temp;
            this.tratamiento_capital.tabla_irregular.data = detalles_irregular;
            this.accesorios.detalles = accesorios;
            if (!res.response) {
              this.finish = true;
              localStorage.removeItem("pasos");
            }
          });
      } else if (this.$route.name == "solicitar_credito_grupal") {
        LineasCreditoApi.crearConfiguracionCreditoGrupal({
            dias_antes_provision: this.interes_ordinario.provision_anticipada,
            producto_monto: this.producto_monto,
            cantidades_grupo : this.cantidades_grupo,
            tratamiento_capital: this.tratamiento_capital,
            interes_ordinario: this.interes_ordinario,
            interes_moratorio: this.interes_moratorio,
            accesorios: this.accesorios.detalles,
            linea: this.linea,
            identificador_fondeador: this.identificador_fondeador,
            grupo_credito_id: this.getLinea.grupo_credito_id,
          })
          .then(() => {
            this.$router.push('/linea/' + this.getLinea.id);
            this.finish = true;
            localStorage.removeItem("pasos");
          });
      } else {
        this.$store
          .dispatch("disposicion/crearDisposicion", {
            pasivo: this.pasivo,
            disposicion_status: "Prospecto",
            dias_antes_provision: this.interes_ordinario.provision_anticipada,
            producto_monto: this.producto_monto,
            tratamiento_capital: this.tratamiento_capital,
            interes_ordinario: this.interes_ordinario,
            interes_moratorio: this.interes_moratorio,
            accesorios: this.accesorios.detalles,
            linea: this.linea,
            identificador_fondeador: this.identificador_fondeador,
            arrendamiento: this.esArrendamiento ? { valor_residual: this.producto_monto.monto * (this.producto_monto.valor_residual / 100) } : null,
            activo_id: this.esArrendamiento ? this.getGarantiaParaArrendar.id : null
          })
          .then(res => {
            this.producto_monto.monto = monto_temp;
            this.tratamiento_capital.fecha_vencimiento = fecha_vencimiento_temp;
            this.tratamiento_capital.tabla_irregular.data = detalles_irregular;
            this.accesorios.detalles = accesorios;
            if (!res.response) {
              this.finish = true;
              if (this.$route.name == "fondear_disposicion") {
                this.$store.dispatch("disposicion/ligarDisposicionPasiva", {
                  disposicion_activa_id: this.$route.params.idDisposicion,
                  disposicion_pasiva_id: res.id,
                  valor_fondeo: this.$route.query.valor_fondeo,
                });
              } else if (this.$route.name == "solicitar_disposicion") {
                localStorage.removeItem("pasos");
              } 
              else if (this.$route.name == "solicitar_arrendamiento" && this.linea && this.linea.id) {
                const activoEstaLigadoALinea = this.getGarantias.find(garantia => garantia.id == this.getGarantiaParaArrendar.id);
                if (!activoEstaLigadoALinea || activoEstaLigadoALinea == null) {
                  this.agregarActivo(this.getGarantiaParaArrendar);
                }
                localStorage.removeItem("pasos");
              }
            }
          });
      }
    },
    // Función encargada de interlazar los datos traidos de la API o de localStorage con la data de vue
    setValuesToModel() {
      this.pasivo =
        this.$route.name == "fondear_disposicion"
          ? true
          : Boolean(this.getDisposicionDetalle.pasiva);
      this.producto_monto.producto_financiero = this.getDisposicionDetalle.productos_financieros_id;
      this.producto_monto.moneda = this.getDisposicionDetalle.moneda_id;
      this.producto_monto.monto = this.getDisposicionDetalle.capital;
      this.tratamiento_capital.tipo_pago = this.getDisposicionDetalle.tipo_pago.clave;
      switch (this.tratamiento_capital.tipo_pago) {
        case "vencimiento":
          this.tratamiento_capital.numero_tiempo = this.getDisposicionDetalle.plazo_dias;
          this.tratamiento_capital.periodo = "days";
          this.tratamiento_capital.fecha_vencimiento = this.$moment(
            this.getDisposicionDetalle.fecha_vencimiento
          ).toDate();
          break;
        // case "regulares":
        //   this.tratamiento_capital.numero_tiempo = this.getDisposicionDetalle.periodos_regulares.cantidad_periodos;
        //   this.tratamiento_capital.periodo = this.getDisposicionDetalle.periodos_regulares.periodos_regulares_id;
        //   break;
        case "irregulares":
          this.tratamiento_capital.tabla_irregular.data = [];
          this.getDisposicionDetalle.fechas_vencimiento_capital.forEach(
            periodo => {
              this.tratamiento_capital.tabla_irregular.data.push({
                fecha_pago: this.$moment(periodo.fecha).toDate(),
                abono_capital: periodo.capital
              });
            }
          );
          break;
      }
      this.linea.localidad_id = this.getDisposicionDetalle.localidades_inegi_id;
      this.interes_ordinario.tasa_base = this.getDisposicionDetalle.interes_ordinario.tasas_base_id;
      this.interes_ordinario.tasa = this.getDisposicionDetalle.interes_ordinario.cantidad;
      this.interes_ordinario.refinanciamiento = this.getDisposicionDetalle.refinanciamiento_intereses.toString();
      this.interes_ordinario.dia_inhabil = this.getDisposicionDetalle.dia_inhabil.toString();
      this.interes_ordinario.tipo_cobro = this.getDisposicionDetalle.tipo_tratamiento_interes_id;
      this.interes_ordinario.fecha_corte = this.getDisposicionDetalle.corte_aniversario.toString();
      this.interes_ordinario.forma_calculo = this.getDisposicionDetalle.entrada_si_salida_no.toString();
      this.interes_ordinario.iva = this.getDisposicionDetalle.iva_interes_ordinario.toString();
      if (this.getDisposicionDetalle.interes_moratorio != null) {
        this.interes_moratorio.tipo = this.getDisposicionDetalle.interes_moratorio.es_en_base_factor.toString();
        this.interes_moratorio.veces = this.getDisposicionDetalle.interes_moratorio.cantidad;
        this.interes_moratorio.tasa = this.getDisposicionDetalle.interes_moratorio.cantidad;
        this.interes_moratorio.tasa_base = this.getDisposicionDetalle.interes_moratorio.tasas_base_id;
        this.interes_moratorio.iva = this.getDisposicionDetalle.iva_interes_moratorio.toString();
      }
      if (this.$route.name != "fondear_disposicion") {
        this.getDisposicionDetalle.costos_extra.forEach(costo => {
          if (costo.catalogo_operaciones.tipo_pago.nombre == "Monto") {
            this.accesorios.detalles.push({
              nombre: costo.catalogo_operaciones_id,
              monto: costo.valor,
              iva: costo.iva,
              tipo: costo.catalogo_operaciones.tipo_pago.nombre,
              fecha_vencimiento: costo.fecha_vencimiento
            });
          } else {
            let valor =
              this.getDisposicionDetalle.capital * (costo.valor / 100);
            this.accesorios.detalles.push({
              nombre: costo.catalogo_operaciones_id,
              monto: costo.valor,
              iva: costo.iva,
              tipo: costo.catalogo_operaciones.tipo_pago.nombre.split(" ")[0],
              monto_porcentaje: valor,
              fecha_vencimiento: costo.fecha_vencimiento
            });
          }
        });
      }
    },
    //Elimina el localstorage para empezar una solicitud de disposición desde el principio
    eliminarContinuacion() {
      localStorage.removeItem("pasos");
      location.reload();
    },

    //Se encarga de asignar un activo para arrendamiento enviado desdel producto_monto
    async asignarBienArrendable(activo) {
      await this.$store.dispatch("garantias/setGarantiaParaArrendar", activo);
    },
    //Si al crear una disposición esta asignada a una línea pero el activo no esta asignado a la línea esta función se encarga de asignarlo
    async agregarActivo(activo) {
      let prelacion = 0;
      const garantia = activo;
      if (garantia.data && garantia.data.atributos.length > 0) {
        const prelacionGarania = garantia.data.atributos.find(atributo => atributo.nombre_atributo == 'Prelación');
        prelacion = prelacionGarania ? prelacionGarania.valor : 0;
      }

      const linea_id = this.linea.id;
      const lineas_credito = {};
      lineas_credito[linea_id] = { prelacion };
      
      this.$store
        .dispatch("lineascredito/asignarGarantiaExistente", {
          garantia_id: garantia.id,
          lineas_credito
        }
      );
    },
    asignarIdentificadorFondeador(identificador) {
      this.identificador_fondeador = identificador;
    }
  },
  watch: {
    producto_objeto(producto) {
      if (producto.tipo_credito_id === 12) {
        this.interes_ordinario.fecha_corte = 1;
        this.interes_ordinario.tipo_cobro = 2;
        this.interes_ordinario.iva = 1;
        this.tratamiento_capital.tipo_pago = "irregulares";
      }
    }
  },
  computed: {
    ...mapGetters("garantias", ["getGarantiaParaArrendar"]),
    ...mapGetters("lineascredito", ["getGarantias"]),

    tipo_cuenta() {
      return this.getCostosExtras.find(
        x => x.id == this.getCuenta.tipo_de_operacion_id
      )
        ? this.getCostosExtras.find(
            x => x.id == this.getCuenta.tipo_de_operacion_id
          )
        : "";
    },
    financiando() {
      return this.$route.name == "financiar_cuenta" ? true : false;
    },
    esArrendamiento(){
      return this.producto_objeto 
        ? this.producto_objeto.tipo_credito.tipo === "Arrendamiento Puro" 
        : this.$route.name == 'solicitar_arrendamiento' || false;
    },
    esCreditoGrupal() {
      return this.$route.name == 'solicitar_credito_grupal';
    },
    producto_objeto() {
      return this.producto_monto.producto_objeto;
    },
    //Propiedad computada para sacar el insoluto restante a la hora de validar ir al siguiente paso en tratamiento capital
    insolutoRestante() {
      let valor = this.tratamiento_capital.tabla_irregular.data.reduce(
        (a, b) => parseFloat(a) + parseFloat(b["abono_capital"] || 0),
        0
      );
      const insoluto = this.producto_monto.monto - valor;
      return this.esArrendamiento 
        ? insoluto - this.tratamiento_capital.tabla_irregular.data[this.tratamiento_capital.tabla_irregular.data.length-1].restante
        : insoluto;
    },
    //Indica dependiendo del paso actual el texto que muestra el botón del step
    stepText() {
      if (Object.values(this.resumen).includes(true)) {
        return "Regresar al resumen";
      } else if (this.activeStep == (this.esCreditoGrupal ? 6 : 5)) {
        if (this.$route.name == "editar_disposicion") {
          return "Editar disposición";
        }
        if (this.$route.name == "fondear_disposicion") {
          return "Fondear disposición";
        }
        if (this.$route.name == "financiar_cuenta") {
          return "Financiar cuenta";
        }
        if (this.$route.name == "solicitar_arrendamiento") {
          return "Solicitar arrendamiento";
        }
        return "Solicitar disposición";
      }
      return "Siguiente";
    },
    ...mapGetters(["getSofom", "getFechaCierre"]),
    ...mapGetters("lineascredito", ["getLinea", "getGarantiasArrendadas"]),
    ...mapGetters("catalogos", [
      "getTiposPago",
      "getPeriodosRegulares",
      "getCostosExtras",
      "getTasasBase",
      "getTratamientoIntereses",
      "getMonedas",
      "getProductosFinancieros"
    ]),
    ...mapGetters("disposicion", ["getDisposicionDetalle", "getCuenta"])
  },
  destroyed() {
    this.$store.commit("garantias/SET_GARANTIA_ARRENDAR", null);
  },
  async mounted() {
    if (this.getDisposicionDetalle && !this.financiando) {
      this.$store.dispatch("disposicion/resetState");
    }
    if (this.$route.params.pasiva == true) {
      this.pasivo = true;
    }
    if (this.getTiposPago.length == 0) {
      this.$store.dispatch("catalogos/getTiposPago");
    }
    if (this.getPeriodosRegulares.length == 0) {
      this.$store.dispatch("catalogos/getPeriodosRegulares");
    }
    if (this.getCostosExtras.length == 0) {
      this.$store.dispatch("catalogos/getCostosExtras");
    }
    if (this.getTasasBase.length == 0) {
      this.$store.dispatch("catalogos/getTasasBase");
    }
    if (this.getTratamientoIntereses.length == 0) {
      this.$store.dispatch("catalogos/getTratamientoIntereses");
    }
    if (this.getMonedas.length == 0) {
      this.$store.dispatch("catalogos/getMonedas");
    }
    if (this.getProductosFinancieros.length == 0) {
      await this.$store.dispatch("catalogos/getProductosFinancieros");
    }
    if (this.$route.params.linea_credito_id || this.$route.query.linea_id) {
      this.$store
        .dispatch(
          "lineascredito/getLineaById",
          this.$route.params.linea_credito_id || this.$route.query.linea_id
        )
        .then(data => {
          this.producto_monto.moneda = data.moneda_id;
          this.producto_monto.producto_financiero =
            data.productos_financieros_id;
          this.pasivo = data.pasiva == 0 ? false : true;
          this.linea.id = data.id;
          const rol = this.$route.params.linea_credito_id ? 'Cliente' : 'Fuente de fondeo'
          this.linea.persona_id = data.relaciones ?? data.relaciones.find(
            x => x.rol.nombre == rol
          ).relacionado.id;

          if (this.esCreditoGrupal) {
            this.producto_monto.monto = this.getLinea.capital_autorizado;

            data.grupo_credito.personas.forEach(persona => {
              this.cantidades_grupo.push({ 
                  persona_id: persona.id,
                  nombre: persona.nombre_completo,
                  cantidad: (data.capital_autorizado / data.grupo_credito.personas.length).toFixed(2)
              });
            });
          }
        });
      if (this.getDestinoRecursos == null) {
        this.$store.dispatch("catalogos/getDestinoRecursos");
      }
    }
    if (
      this.$route.name == "editar_disposicion" ||
      (this.$route.name == "fondear_disposicion" &&
        Boolean(this.$route.query.calzar))
    ) {
      this.editar = true;
      this.welcome = false;
      if (!this.getDisposicionDetalle) {
        this.$store
          .dispatch(
            "disposicion/getDisposicionDetalle",
            this.$route.name == "editar_disposicion"
              ? this.$route.params.idEditarDisposicion
              : this.$route.params.idDisposicion
          )
          .then(disposicion => {
            this.dias_antes_provision =
              disposicion.credito_core.dias_antes_provision;
            this.credito_activo =
              disposicion.credito_status.activo == 1 ? true : false;
            this.setValuesToModel();
          });
      } else {
        this.setValuesToModel();
      }
    } else if (this.$route.name == "solicitar_disposicion") {
      if (localStorage.getItem("pasos")) {
        const pasos = JSON.parse(localStorage.getItem("pasos"));
        this.activeStep = 0;
        Object.keys(pasos).forEach(key => {
          this.$data[key] = pasos[key];
        });
        if (this.activoParaArrendamiento) {
          await this.$store.dispatch("garantias/setGarantiaParaArrendar", this.activoParaArrendamiento);
        }
        this.continuacion = true;
      }
    }
    if (this.$route.name == "fondear_disposicion") {
      this.pasivo = true;
      this.linea = {
        persona_id: this.$route.query.fuente_fondeo,
        id: this.$route.query.linea_id,
        destino_recursos: this.$route.query.destino_recursos
      };
      this.identificador_fondeador = this.$route.query.identificador_fondeador;
    }
    if (this.financiando) {
      if (this.getCuenta.lineadecredito_id) {
        await this.$store.dispatch(
          "lineascredito/getLineaById",
          this.getCuenta.lineadecredito_id
        );
      }
      this.welcome = false;
      this.linea = {
        persona_id: this.getCuenta.cliente,
        id:
          this.getCuenta.lineadecredito_id ||
          this.getDisposicionDetalle.linea_credito[0].id
      };
      this.producto_monto = {
        producto_financiero:
          this.getLinea.producto_financiero.id ||
          this.getDisposicionDetalle.producto_financiero.id,
        producto_objeto:
          this.getLinea.producto_financiero ||
          this.getDisposicionDetalle.producto_financiero,
        moneda: this.getCuenta.moneda_id,
        monto:
          this.tipo_cuenta.tipos_pago_ce_id == 1
            ? (this.getLinea.capital_autorizado ||
                this.getDisposicionDetalle.capital) *
              (this.getCuenta.cantidad / 100)
            : this.getCuenta.cantidad
      };
    }

    if (this.$route.name == 'solicitar_arrendamiento' || this.$route.name == 'solicitar_credito_grupal')
      this.closeWelcome();
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.b-steps .steps .step-items .step-item {
  .step-details {
    background: transparent;
    .step-title {
      font-weight: 400;
      font-size: 1rem;
    }
  }
  .step-marker {
    background-color: var(--grey-lighter);
  }
  &::before {
    height: 0.15rem !important;
  }
}
.b-steps.editar .steps.is-pasivo .step-items .step-item .step-marker {
  background-color: $pasivo;
}
.b-steps.editar .steps.is-primary .step-items .step-item .step-marker {
  background-color: $primary;
}
.step-content {
  text-align: left;
  padding: 1rem 0 !important;
}
.form-container {
  padding: 1.5rem;
  .field.label-big {
    flex-wrap: wrap;
    label {
      width: 100%;
      font-size: 1.8rem;
    }
  }
}
label.label-big {
  font-size: 1.8rem;
}
</style>
