<template>
  <div class="columns mt-3 is-multiline">
    <div class="column is-12">
      <div class="form-container">
        <label class="label-big font-bold has-text-grey-darker">
          Cantidades
        </label>
        <div v-for="persona in personas" :key="persona.persona_id" class="columns mt-1">
          <div class="column text-center my-auto">
            <p class="text-lg font-medium mt-3">{{ persona.nombre }}</p>
          </div>
          <div class="column">
            <valid-currency-input
              :rules="`currency|max_value:${maxCantidad(persona)}`"
              label="Cantidad"
              :placeholder="'Introduzca un valor (max: ' + maxCantidad(persona) + ')...'"
              v-model="persona.cantidad"
              :expanded="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ValidSelect from "@/components/form/ValidSelect";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";

export default {
  name: "cantidades_grupo",
  components: {
    ValidCurrencyInput, 
    // ValidSelect
  },
  props: {
    personas: Array,
    capital: Number,
  },
  methods: {
    maxCantidad(persona_actual) {
        const sumaCantidadPersonas = this.personas.reduce((suma, persona) => suma + persona.cantidad, 0);

        return this.capital - (sumaCantidadPersonas - persona_actual.cantidad);
    }
  }
};
</script>